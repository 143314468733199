import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/case1',
    name: 'case1',
    component: () => import('@/views/case1.vue')
  },
  {
    path: '/case2',
    name: 'case2',
    component: () => import('@/views/case2.vue')
  },
  {
    path: '/case3',
    name: 'case3',
    component: () => import('@/views/case3.vue')
  },
  {
    path: '/phone-robot',
    name: 'phoneRobot',
    component: () => import('@/views/phone-robot/index.vue')
  },
  {
    path: '/text-robot',
    name: 'textRobot',
    component: () => import('@/views/text-robot/index.vue')
  },
  {
    path: '/seat',
    name: 'seat',
    component: () => import('@/views/seat/index.vue')
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('@/views/community/index.vue')
  },
  {
    path: '/culture-tourism',
    name: 'cultureTourism',
    component: () => import('@/views/culture-tourism/index.vue')
  },
  {
    path: '/gov-data',
    name: 'govData',
    component: () => import('@/views/gov-data/index.vue')
  },
  {
    path: '/gov-model',
    name: 'govModel',
    component: () => import('@/views/gov-model/index.vue')
  },
  {
    path: '/quality-check',
    name: 'qualityCheck',
    component: () => import('@/views/quality-check/index.vue')
  },
  {
    path: '/outbound',
    name: 'outbound',
    component: () => import('@/views/outbound/index.vue')
  },
  {
    path: '/icss-sfi',
    name: 'icssSfi',
    component: () => import('@/views/icss-sfi/index.vue')
  },
  {
    path: '/icsm-is',
    name: 'icsmIs',
    component: () => import('@/views/icsm-is/index.vue')
  },
  {
    path: '/agr-platform',
    name: 'agrPlatform',
    component: () => import('@/views/agr-platform/index.vue')
  },
  {
    path: '/agr-prod',
    name: 'agrProd',
    component: () => import('@/views/agr-prod/index.vue')
  },
  {
    path: '/ai-voice-ctrl',
    name: 'aiVoiceCtrl',
    component: () => import('@/views/ai-voice-ctrl/index.vue')
  },
  {
    path: '/gpt-private',
    name: 'gptPrivate',
    component: () => import('@/views/gpt-private/index.vue')
  },
  {
    path: '/gpt-app',
    name: 'gptApp',
    component: () => import('@/views/gpt-app/index.vue')
  },
  // {
  //   path: '/active',
  //   name: 'Active',
  //   component: () => import('@/views/Active.vue')
  // },
  // {
  //   path: '/gallery',
  //   name: 'gallery',
  //   component: () => import('@/views/gallery.vue')
  // },
  // {
  //   path: '/gallerypc',
  //   name: 'gallerypc',
  //   component: () => import('@/views/gallerypc.vue')
  // },
  // {

  //   path: '/collection',
  //   name: 'collection',
  //   component: () => import('@/views/collection.vue')
  // },
  // {

  //   path: '/customizing',
  //   name: 'customizing',
  //   component: () => import('@/views/customizing.vue')
  // },

  {

    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue')
  },

  // {
  //   path: '/recruiting',
  //   name: 'recruiting',
  //   component: () => import('@/views/recruiting.vue')
  // },
  // {
  //   path: '/recuiDetail',
  //   name: 'recuiDetail',
  //   component: () => import('@/views/recuiDetail.vue')
  // },
  {

    path: '/setParty',
    name: 'setParty',
    component: () => import('@/views/setParty.vue')
  },
  {

    path: '/setPartyDetail',
    name: 'setPartyDetail',
    component: () => import('@/views/setPartyDetail.vue')
  },

  // {
  //   path: '/help',
  //   name: 'help',
  //   component: () => import('@/views/help.vue')
  // },
  // {
  //   path: '/help1',
  //   name: 'help1',
  //   component: () => import('@/views/help1.vue')
  // },

  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('@/views/team.vue')
  },
  {
    path: '/teamdetal',
    name: 'teamdetal',
    component: () => import('@/views/teamdetal.vue')
  },
  // {
  //   path: '/save',
  //   name: 'save',
  //   component: () => import('@/views/save.vue')
  // },
  // {
  //   path: '/chatAi',
  //   name: 'chatAi',
  //   component: () => import('@/views/chatAi.vue')
  // },
  // {
  //   path: '/Agreement',
  //   name: 'Agreement',
  //   component: () => import('@/views/Agreement.vue')
  // },
  // {
  //   path: '/Privacy',
  //   name: 'Privacy',
  //   component: () => import('@/views/Privacy.vue')
  // },
  // {
  //   path: '/digital',
  //   name: 'digital',
  //   component: () => import('@/views/digital.vue')
  // },

  // {
  //   path: '/shoppingMall',
  //   name: 'shoppingMall',
  //   component: () => import('@/views/shoppingMall.vue')
  // },
  // {
  //   path: '/chainReform',
  //   name: 'chainReform',
  //   component: () => import('@/views/chainReform.vue')
  // },


  // {

  //   path: '/detail',
  //   name: 'detail',
  //   component: () => import('@/views/detail.vue')
  // },

  // {

  //   path: '/wky',
  //   name: 'wky',
  //   component: () => import('@/views/wky.vue')
  // },
  // {

  //   path: '/computing',
  //   name: 'computing',
  //   component: () => import('@/views/computing.vue')
  // },


]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  },
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
export default router
