export default {
	//客户案例
	projectCate: "/v1/projectCate",
	casusList: "v1/casusList",
	//新闻
	newsCenter: "v1/newsCenter",
	articleList: "v1/articleList",
	//人才招聘
	articleTypeList: "v1/articleTypeList",

	cateList: "v1/cateList",

	menu: "v1/menu",

	casusList: "v1/casusList",

	projectCate: "v1/projectCate",

	craftCate: "v1/craftCate",
	// 保存客户信息
	saveClient: "website/websiteExternal/saveClient",
	// 内容发布列表查询
	listArticle: "website/websiteExternal/listArticle",
	// 内容发布详情
	detailArticle: "website/websiteExternal/detailArticle",
	// 进入官网
	saveBrowse: "website/websiteExternal/saveBrowse",
	// 离开官网
	leaveBrowse: "website/websiteExternal/leaveBrowse",
	// 轮播图列表查询
	listSlideshow: "website/websiteExternal/listSlideshow",
	// 轮播图详情
	detailSlideshow: "website/websiteExternal/detailSlideshow",
	// 官网菜单点击记录
	saveClick: "website/websiteExternal/saveClick",
	// 党建轮播图列表查询
	listPartySlideshow: "website/websiteExternal/listPartySlideshow",
	// 查询党建信息
	partyDetail: "website/websiteExternal/partyDetail"
};
