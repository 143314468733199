<template>
	<div id="app">
		<transition name="slide-fade" mode="out-in">
			<router-view v-if="shows" />
			<div v-else class="jzzs">
				<div>
					<van-loading type="spinner" color="#000" />
				</div>
				<div>{{ tet }}</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	provide() {
		//父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			reload: this.reload,
			show: false,
			timer: false,
			screenWidth: document.body.clientWidth,
		};
	},
	data() {
		return {
			shows: true,
			tet: "loading ...",
			appTimer: void 0,
			ip_addr: '',
			userId: ''
		};
	},
	async mounted() {
		let res = await this.$http.get(this.$api.saveBrowse);
		this.userId = res.data.data || ''
		// console.log('this.userId----->', this.userId)

		document.addEventListener('visibilitychange', this.handleVisiable)

		window.onresize = () => {
			return (() => {
				window.screenWidth = document.body.clientWidth;
				this.screenWidth = window.screenWidth;
				// 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
				this.show = this.screenWidth > 800 ? false : true;
				this.$store.commit("phone", this.show);
			})();
		};
	},
	created() {
		this.screenWidth = document.body.clientWidth;
		this.show = this.screenWidth > 800 ? false : true;
		this.$store.commit("phone", this.show);
	},
	destroyed() {
		this.$http.get(this.$api.leaveBrowse, {
			id: this.userId
		});
		document.removeEventListener('visibilitychange', this.handleVisiable)
	},
	methods: {
		handleVisiable(e) {
			switch (e.target.visibilityState) {
				case 'prerender':
					// console.log('网页预渲染，内容不可见')
					break;
				case 'hidden':
					// console.log('内容不可见，处理后台、最小化、锁屏状态')
					// alert('警告！你已经离开当前答题页面');
					this.$http.get(this.$api.leaveBrowse, {
						id: this.userId
					});
					break;
				case 'visible':
					// console.log('处于正常打开')
					break;
			}
		},
		reload() {
			this.isRouterAlive = false; //先关闭，
			this.$nextTick(function () {
				this.isRouterAlive = true; //再打开
			});
		},
	},
};
</script>

<style lang="scss">
#app {
	background: #f8f8f8 !important;
}

::-webkit-scrollbar {
	width: 0 !important;
}

.van-toast {
	font-size: 16px !important;
	line-height: 36px !important;
	// background-color:#ff9ab5 !important;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif, PingFang SC;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	color: #2c3e50;
	width: 100%;
	overflow: hidden;
}

@font-face {
	font-family: DINAlternate-Bold;
	src: url("~@/assets/font/DINPro-Bold.otf");
}

@font-face {
	font-family: Arial;
	src: url("~@/assets/font/Arial.ttf");
}

.page {
	text-align: center;
}

@font-face {
	font-family: PingFang SC;
	src: url("~@/assets/font/PingFang SC Regular.ttf");
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.van-popup__close-icon {
	font-size: 36px !important;
}

.jzzs {
	height: 100vh;
}

.jzzs div {
	padding-top: 20%;
	font-size: 23px;
	color: #2c3e50;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadeIn {
	animation-duration: .8s;
	animation-name: fadeIn;
}


@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 40px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUp {
	animation-duration: .8s;
	animation-name: fadeInUp;
}

@keyframes fadeInDow {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDow {
	animation-duration: .8s;
	animation-name: fadeInDow;
}



@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(40px, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInRight {
	animation-duration: .8s;
	animation-name: fadeInRight;
}


@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-40px, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInLeft {
	animation-duration: .8s;
	animation-name: fadeInLeft;
}

@keyframes fadeScale {
	from {
		opacity: 1;
		transform: scale(1);
		text-shadow: 0 0 calw(5vw) rgba(white, 1);
	}

	to {
		opacity: 0.8;
		transform: scale(0.9);
		text-shadow: 0 0 calw(20vw) rgba(white, 0.5);
	}
}

.fadeScale {
	transform: 0.3;
	animation: fadeScale .5s infinite alternate;
}

@keyframes move {
	0% {
		transform: translateZ(-500px) rotate(0deg);
	}

	100% {
		transform: translateZ(500px) rotate(0deg);
	}
}

.fadeMove {
	animation: move 12s infinite linear;
	animation-fill-mode: forwards;
}


@keyframes Rotate {
	100% {
		transform: rotate(360deg);
	}
}

.fadeRotate {
	transform: 0.3;
	animation: fadeScale .5s infinite alternate;
}
</style>
